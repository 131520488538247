import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import WorkerParent from '../../components/WorkerParent.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I've heard some people say I should use web workers, but that always seemed like a pain.`}</p>
    <p>{`Tonight I'm going to face my fear and use a worker. My goal is pretty simple - click a button and have it add 2 to a count.`}</p>
    <h3 {...{
      "id": "problems"
    }}><a parentName="h3" {...{
        "href": "#problems"
      }}>{`Problems:`}</a></h3>
    <p>{`problem 1: don't know how to create a web worker`}</p>
    <p>{`problem 2: I use compiled javascript`}<br />{`
note - My javascript is passed through Gatsby, so I don't have access to webpack`}</p>
    <p>{`problem 3: don't know how to talk to a web worker`}</p>
    <h4 {...{
      "id": "step-1-build-a-web-worker"
    }}><a parentName="h4" {...{
        "href": "#step-1-build-a-web-worker"
      }}>{`Step 1: build a web worker`}</a></h4>
    <p>{`Is there some magic code that I can copy and paste?`}</p>
    <p>{`Yes - `}<a parentName="p" {...{
        "href": "https://github.com/webpack-contrib/worker-loader"
      }}>{`https://github.com/webpack-contrib/worker-loader`}</a></p>
    <p>{`Example Worker`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// Worker.ts`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` ctx`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Worker`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` self `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`as`}</span>{` any

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Post data to parent thread`}</span>{`
ctx`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`postMessage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` foo`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'foo'`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Respond to message from parent thread`}</span>{`
ctx`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`addEventListener`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'message'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token parameter"
        }}>{`event`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`event`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}</code></pre>
    <p>{`Example script that loads a worker (don't worry, you don't have to use typescript - I just want all the help I can get from intellisense)`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// WorkerParent.ts`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Worker`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'worker-loader!./Worker'`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` worker `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Worker`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`

worker`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`postMessage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` a`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
worker`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method-variable function-variable method function property-access"
        }}>{`onmessage`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token parameter"
        }}>{`event`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

worker`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`addEventListener`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'message'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token parameter"
        }}>{`event`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}</code></pre>
    <p>{`Neat, now how do I use it?`}</p>
    <h3 {...{
      "id": "step-2---configuring-workers"
    }}><a parentName="h3" {...{
        "href": "#step-2---configuring-workers"
      }}>{`Step 2 - configuring workers`}</a></h3>
    <p>{`Turns out you drop this in your webpack config:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// webpack.config.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  loader`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'worker-loader'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` publicPath`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'/workers/'`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <h4 {...{
      "id": "step-2a-gatsby-makes-this-harder"
    }}><a parentName="h4" {...{
        "href": "#step-2a-gatsby-makes-this-harder"
      }}>{`Step 2a: gatsby makes this harder`}</a></h4>
    <p>{`Gatsby lets you update the config by updating gatsby-node, so I'm changing it mine to configure the workers like so:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// gatsby-node.js`}</span>{`
exports`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method-variable function-variable method function property-access"
        }}>{`onCreateWebpackConfig`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  actions`}<span parentName="span" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` replaceWebpackConfig `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  getConfig`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` config `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`getConfig`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`

  config`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`module`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`rules`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`push`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    test`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token regex"
        }}><span parentName="span" {...{
            "className": "token regex-delimiter"
          }}>{`/`}</span><span parentName="span" {...{
            "className": "token regex-source language-regex"
          }}>{`\\.worker\\.js$`}</span><span parentName="span" {...{
            "className": "token regex-delimiter"
          }}>{`/`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    use`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` loader`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'worker-loader'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` inline`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`

  config`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`output`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`globalObject`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'this'`}</span>{`

  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`replaceWebpackConfig`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`config`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`With that in place, I'm able to load the parent, and recieve the dummy message from the sample!`}</p>
    <h3 {...{
      "id": "step-3-send-information-to-and-from-the-worker"
    }}><a parentName="h3" {...{
        "href": "#step-3-send-information-to-and-from-the-worker"
      }}>{`Step 3: send information to and from the worker`}</a></h3>
    <p>{`Fortunately, it looks like the example files gave me the tools that I need for a simple message. The flow looks like this:`}</p>
    <ol>
      <li parentName="ol">{`My parent starts up a new worker`}</li>
      <li parentName="ol">{`The parent posts a message, and binds an "onmessage" event`}</li>
      <li parentName="ol">{`The child posted a message of `}<inlineCode parentName="li">{`{foo: "foo"}`}</inlineCode></li>
      <li parentName="ol">{`I could access that message under `}<inlineCode parentName="li">{`event.message.data`}</inlineCode>{` in the onmessage listener`}</li>
    </ol>
    <p>{`So, that answers how to get information back from the worker, but now I need to figure out how to access information while inside the worker.`}</p>
    <p>{`Do debugger statements work yet?`}</p>
    <p>{`It turns out that DevTools aren't quite there yet. Well, I know that the event type is a `}<inlineCode parentName="p">{`MessageEvent`}</inlineCode>{`, thanks to typescript, so a quick web search reveals that there's a `}<inlineCode parentName="p">{`data`}</inlineCode>{` payload I can access inside the worker.`}</p>
    <h3 {...{
      "id": "problems-resolved"
    }}><a parentName="h3" {...{
        "href": "#problems-resolved"
      }}>{`Problems Resolved!`}</a></h3>
    <p>{`Let's wrap up with a simple example.`}</p>
    <p>{`Here's our new worker:`}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// Worker.ts`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` ctx`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Worker`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` self `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`as`}</span>{` `}<span parentName="code" {...{
          "className": "token builtin"
        }}>{`any`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Respond to message from parent thread`}</span>{`
ctx`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`addEventListener`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'message'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` event `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Read number from data`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` count`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token builtin"
        }}>{`number`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` event`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`data`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`count`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`||`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// respond with next value`}</span>{`
  ctx`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`postMessage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` next`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` count `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`+`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}</code></pre>
    <p>{`And the WorkerParent, now a React component:`}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// WorkerParent.tsx`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useEffect`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` useState `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"react"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`WebpackWorker`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"worker-loader!*"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`interface`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Props`}</span></span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`WorkerState`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`WebpackWorker`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`|`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword nil"
        }}>{`undefined`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`WorkerParent`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`props`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Props`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` props`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`count`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` setCount`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useState`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`worker`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` setWorker`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token generic-function"
        }}><span parentName="span" {...{
            "className": "token function"
          }}>{`useState`}</span><span parentName="span" {...{
            "className": "token generic class-name"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token maybe-class-name"
            }}>{`WorkerState`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useEffect`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Loading countworker"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` countListenerWorker `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
        `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"worker-loader!./countListener.worker.ts"`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` loaded `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`countListenerWorker`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`default`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Worker loaded"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` loaded`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      loaded`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method-variable function-variable method function property-access"
        }}>{`onmessage`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` event `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` next `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` event`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`data`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`next`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setCount`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`next`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`setWorker`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`loaded`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`handleClick`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    worker`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`postMessage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` count `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
    `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
      `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{`h4`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Count`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`count`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span>{`h4`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
      `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{`button onClick`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`handleClick`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` disabled`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span>{`worker`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
        `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Increase`}</span>{`
      `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span>{`button`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
    `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`default`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`WorkerParent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`A note on this - during local development, or a scenario where you're not doing SSR, you can use an ordinary import statement. Since Gatsby renders my code in Node.js though, I need to structure the web worker using the new dynamic import syntax, so that I can conditionally load it only in the browser.`}</p>
    <WorkerParent mdxType="WorkerParent" />
    <p style={{
      marginTop: "24px"
    }}>
And that about does it!
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      